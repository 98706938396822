import * as React from "react";
import * as styles from "../styles/workshop.module.scss";

const Workshop = ({ date, text }) => {
  return (
    <div className={styles.workshop}>
      <p className={styles.text}>{text}</p>
      <p className={styles.date}>{date}</p>
    </div>
  );
};

export default Workshop;
