import * as React from "react";

import SEO from "../components/seo";
import Workshop from "../components/workshop";

import * as styles from "../styles/homepage.module.scss";

import BlackHXOUSE from "../images/black-hxouse.png";
import HXOUSE from "../images/hxouse.png";
import Canada from "../images/canada.png";
import Tag from "../images/tag.png";
import TitleHorizontal from "../images/title-large.svg";
import TitleVertical from "../images/title-vertical.svg";

import Animation from "../videos/animation.mp4";

const IndexPage = () => {
  return (
    <>
      <SEO />
      <div className={styles.page}>
        <video className={styles.video} autoPlay playsInline loop muted>
          <source src={Animation} type="video/mp4" />
        </video>
        <main className={styles.wrapper}>
          <header>
            <img className={styles.headerDesktop} src={TitleHorizontal} />
            <img className={styles.headerMobile} src={TitleVertical} />
          </header>
          <section className={styles.main}>
            <div className={styles.pageContent}>
              <div className={styles.content}>
                <div className={styles.leftContent}>
                  <p className={styles.body}>
                  BLACK HXOUSE - BUSINESS ESSENTIALS returns in May with five workshops that focus on the Core Concepts necessary to start your business.
                  <br/>
                  <br/>
                  Our workshops are accessible to entrepreneurs at all stages of their journey and address everything from the adoption of AI tools to learning management skills.
                  <br/>
                  <br/>
                  Business Essentials pushes entrepreneurs to tap into an innovative mindset and cultivate a culture of sustainable business practices. The program will help Tenants form a strong knowledge-based foundation to support the development of their business strategies.
                  </p>
                  <section className={styles.apply}>
                    <a
                      target="_blank"
                      href="https://forms.gle/Dgnbv3xbSJ8pNhbn9"
                      rel="noopener noreferrer"
                      className={styles.button}
                    >
                      Apply Now
                    </a>
                  </section>
                </div>
                <div className={styles.rightContent}>
                  <p className={styles.core}>CORE CONCEPTS WORKSHOPS — </p>
                  <Workshop
                    date="SATURDAY MAY 25 / 1PM — 5PM"
                    text="AI FOR YOUR BUSINESS"
                  />
                  <Workshop
                    date="SUNDAY MAY 26 / 1PM — 5PM"
                    text="SERVICE DESIGN & DESIGN THINKING"
                  />
                  <Workshop
                    date="SATURDAY JUNE 1 / 1PM — 5PM"
                    text="FINANCIAL FRAMEWORKS FOR AN EMERGING BUSINESS"
                  />
                  <Workshop
                    date="SATURDAY JUNE 8 / 1PM — 5PM"
                    text="HUMAN RESOURCES"
                  />
                  <Workshop
                    date="SATURDAY JUNE 29 / 1PM — 5PM"
                    text="LEADERSHIP & MANAGEMENT"
                  />
                </div>
                <section className={styles.applyMobile}>
                    <a
                      target="_blank"
                      href="https://forms.gle/Dgnbv3xbSJ8pNhbn9"
                      rel="noopener noreferrer"
                      className={styles.button}
                    >
                      Apply Now
                    </a>
                  </section>
              </div>
            </div>
          </section>
          <footer className={styles.footer}>
            <div className={styles.logos}>
              <img className={styles.hxouse} src={HXOUSE} alt="HXOUSE Logo" />
              <img
                className={styles.bhxouse}
                src={BlackHXOUSE}
                alt="Black HXOUSE Logo"
              />
            </div>
            <a 
              target="_blank"
              href="https://feddev-ontario.canada.ca/"
              rel="noopener noreferrer"
            >
              <img className={styles.logoAlt} src={Canada} alt="Canada Logo" />
            </a>
          </footer>
        </main>
      </div>
    </>
  );
};

export default IndexPage;
