// extracted by mini-css-extract-plugin
export var ___gatsby = "homepage-module--___gatsby--370aa";
export var apply = "homepage-module--apply--2741b";
export var applyMobile = "homepage-module--applyMobile--a4f7b";
export var bhxouse = "homepage-module--bhxouse--77e54";
export var body = "homepage-module--body--fee33";
export var button = "homepage-module--button--7b7be";
export var content = "homepage-module--content--42a1a";
export var core = "homepage-module--core--ab50a";
export var description = "homepage-module--description--27cea";
export var footer = "homepage-module--footer--b9939";
export var gatsbyFocusWrapper = "homepage-module--gatsby-focus-wrapper--dee0b";
export var header1 = "homepage-module--header1--7f6ed";
export var header2 = "homepage-module--header2--823c3";
export var headerDesktop = "homepage-module--headerDesktop--d918a";
export var headerMobile = "homepage-module--headerMobile--05d33";
export var hxouse = "homepage-module--hxouse--e8a6d";
export var leftContent = "homepage-module--leftContent--a6618";
export var logoAlt = "homepage-module--logoAlt--682b4";
export var logos = "homepage-module--logos--53468";
export var main = "homepage-module--main--a6489";
export var mobileDescription = "homepage-module--mobileDescription--592b6";
export var mobileTag = "homepage-module--mobileTag--2a7d1";
export var mobileVideo = "homepage-module--mobileVideo--0194e";
export var page = "homepage-module--page--1a395";
export var pageContent = "homepage-module--pageContent--47aea";
export var rightContent = "homepage-module--rightContent--7f0ad";
export var tag = "homepage-module--tag--fff6d";
export var video = "homepage-module--video--bb178";
export var wrapper = "homepage-module--wrapper--78561";